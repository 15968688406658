import Vue from "vue";

Vue.mixin({
  methods: {
    alertCatchError(e) {
      if (e == 401) {
        this.$store.commit("SET_USER");
        window.localStorage.removeItem("DTA");

        this.$message({
          message: "กรุณาล็อกอินเข้าสู่ระบบอีกครั้ง",
          type: "warning",
          duration: 9000,
        });
        // window.location.href = "/";
        this.$router.go(this.$router.currentRoute);
      } else {
        this.$alert(`${e}`, {
          confirmButtonText: "OK",
          type: "error",
        });
      }
    },
  },
});
