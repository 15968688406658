export default {
    // user logout
    logout({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                commit('SET_USER', null)
                window.localStorage.removeItem("DTA");

                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('SET_USER', null)
            window.localStorage.removeItem("DTA");
            resolve()
        })
    }
}
