import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "@/styles/index.scss";
import moment from "moment";
import momentTH from "moment/locale/th";
import VueAxios from "vue-axios";
import axios from "axios";
import VueMoment from "vue-moment";
import moments from "moment-timezone";
import "@/service/alert.js";
import "@/service/date.js";
import VueJwtDecode from 'vue-jwt-decode'
import AddToCalendar from 'vue-add-to-calendar';

Vue.config.productionTip = false;
Vue.use(AddToCalendar);
Vue.use(VueMoment, {
  moments,
});
Vue.use(VueAxios, axios);
Vue.use(VueJwtDecode)
Vue.filter("timeNormal", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(date).format("DD/MM/YYYY");
  }
  return res;
});

new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");
