import Vue from "vue";
import momentTH from "moment/locale/th";
import moment from "moment";

Vue.mixin({
  methods: {
    showDateTH(date) {
      // let day = date.split("/");
      let DD = new Date(
        moment(date).format("YYYY"),
        moment(date).format("MM") - 1,
        moment(date).format("DD")
      );
      let newDateTH = moment(DD)
        .locale("th", momentTH)
        .locale("th", momentTH)
        .format("วันddddที่ Do MMMM ");
      let yearTH = Number(moment(DD).format("YYYY")) + 543;
      return newDateTH + yearTH;
    },
    sessionTime(time) {
      let Time = time.split(":");
      let TimePrint = Time[0] + `:` + Time[1];
      return TimePrint;
    },
    sessionTimeStamp(date,time) {
      let momentDate = moment(date)
      let momentTime = moment(time, 'HH:mm');
      momentDate.set({
        hour:   momentTime.get('hour'),
        minute: momentTime.get('minute'),
        second: momentTime.get('second')
      })
      return new Date(momentDate)
    }
  },
});
