export default {

  // SET_TOKEN: (state, token) => {
  //   state.token = token
  // },
  // SET_AVATAR: (state, avatar) => {
  //   state.avatar = avatar
  // },
  // SET_ROLES: (state, roles) => {
  //   state.roles = roles
  // },
  SET_USER_OBJ: ( state, payload ) =>
  {
    state.user[payload.label] = payload.value;
  },
  SET_USER: (state, payload) => {
    state.user = payload;
  },
};
