<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  // beforeCreate(){
  //   this.$liff.init({liffId: '1655765368-GVMBopQJ'})
  // }
};
</script>
