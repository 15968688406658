import Vue from "vue";
import Vuex from "vuex";
import actions from "@/store/actions";
import getters from "@/store/getters";
import mutations from "@/store/mutations";
import initState from "@/store/initState";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionNamespace: "_dta",
  encryptionSecret: "!#$^%^@dtA@k2021DigiTal",
});

Vue.use(Vuex);
const isProd = process.env.NODE_ENV === "production";
const storage = {
  getItem: (key) => ls.get(key),
  setItem: (key, value) => ls.set(key, value),
  removeItem: (key) => ls.remove(key),
};
const plugins = [
  createPersistedState({
    key: "DTA",
    storage: isProd ? storage : null,
  }),
];

export default new Vuex.Store({
  state: initState(),
  getters,
  mutations,
  actions,
  plugins,
});
