import axios from "axios";

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 2000 * 60,
});

export const HTTPDtm = axios.create({
  baseURL: process.env.VUE_APP_DTM_BASE_URL,
  // timeout: 2000 * 60,
});
export const HTTPBBB = axios.create({
  baseURL: process.env.VUE_APP_BBB_BASE_URL_API,
  // timeout: 2000 * 60,
});
