import {HTTP, HTTPDtm} from "@/service/axios"
import store from "@/store"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import Vue from "vue"
import VueJwtDecode from "vue-jwt-decode"
import VueRouter from "vue-router"

const isAuthenticated = async (to, from, next) => {
  if (typeof store.state.user != "undefined" && store.state.user != null) {
    if (to.path == "/sign-in") {
      next("/calendar")
    } else {
      next()
    }
  } else {
    next("/sign-in")
  }
}

// Vue.prototype.$liff = window.liff
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import("@/views/SignIn.vue"),
    redirect: "/sign-in",
  },
  {
    path: "/learning-hour",
    name: "Learning",
    component: () => import("@/views/Learning/Learning.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/SignIn.vue"),
    // beforeEnter: isAuthenticated,
  },
  {
    path: "/calendar",
    name: "Event",
    component: () => import("@/views/Event.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/subject",
    name: "Subject",
    component: () => import("@/views/Subject.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/subject/:id",
    name: "SubjectId",
    component: () => import("@/views/SubjectDetail.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/subject/detail/:id",
    name: "SubjectDetail",
    component: () => import("@/views/Learning/SubjectDetail.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/subject/detail/:idSemester/:id",
    name: "SubjectSemesterDetail",
    component: () => import("@/views/Learning/SubjectDetail.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/subject/:subid/:id",
    name: "SubjectBadgeDetail",
    component: () => import("@/views/SubjectBadgeDetail.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/learning/semester/:id",
    name: "LearningSemester",
    component: () => import("@/views/Learning/LearningSemester.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/badges-history",
    name: "BadgesHistory",
    component: () =>
      import("@/components/BadgesHistory/BadgesHistoryDetail.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/badges-history/:id",
    name: "BadgeHistoryById",
    component: () => import("@/views/BadgeHistoryById.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/badges-history/:id/:idBadge",
    name: "BadgeHistoryDetailById",
    component: () => import("@/views/BadgeHistoryDetailById.vue"),
    beforeEnter: isAuthenticated,
  },

  // {
  //   path: "/badges-history/semester/:id",
  //   name: "BadgesHistoryDetail",
  //   component: () =>
  //     import("@/components/BadgesHistory/BadgesHistoryDetail.vue"),
  //   beforeEnter: isAuthenticated,
  // },
  {
    path: "/badges-history/semester/subject/:id",
    name: "BadgesSubjectId",
    component: () => import("@/components/BadgesHistory/BadgesSubject.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/certificate",
    name: "LearningCertificate",
    component: () => import("../views/Learning/Certificate.vue"),
    beforeEnter: isAuthenticated,
  },
  // Certificate
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    beforeEnter: isAuthenticated,
  },
  // {
  //   path: "/event",
  //   name: "Event",
  //   component: () => import("@/views/Event.vue"),
  //   beforeEnter: isAuthenticated,
  // },
  {
    path: "/join-session/:id",
    name: "joinSession",
    component: () => import("@/views/JoinSession.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
]

const router = new VueRouter({
  routes,
  mode: "history",
})

const whiteList = ["/"] // no redirect whitelist
const title = "DTA"

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // var authLink = to.query.from == 'line' ? "https://liff.line.me/1655765368-GVMBopQJ?redirect=" : "https://dtm.avalue.co.th/login/account?redirect="
  // var authLink = "https://dtm.avalue.co.th/login/account?redirect=" +
  var authLink =
    process.env.VUE_APP_BASE_URL +
    "?redirect=" +
    process.env.VUE_APP_BASE_URL +
    to.path
  console.log("beforeEach authLink", authLink)
  // window.location = authLink + process.env.VUE_APP_BASE_URL
  // set page title
  document.title = to.meta.title ? `${to.meta.title} - ${title}` : title

  let getCookieId = {
    providerId: process.env.VUE_APP_DTM_PROVIDER_ID,
    key: process.env.VUE_APP_DTM_KEY,
  }

  let User = {}
  let session = ""
  session = await getCookie(getCookieId, authLink)
  // alert("session", JSON.stringify(session))
  console.log("session", session)

  // console.log("store.state.user",JSON.stringify(store.state.user))

  if (session.accessToken) {
    let decoded = VueJwtDecode.decode(session.accessToken)
    console.log("decoded", decoded)
    User = {
      id: decoded.userId,
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      isVerify: "",
      accessToken: session.accessToken,
      refreshToken: session.refreshToken,
    }

    // console.log("session accessToken",session.accessToken)
    // console.log("User",User)

    User = await getProfile(User, session, getCookieId, authLink)

    // console.log("getProfile User", User)

    await saveUser(User, session, getCookieId, authLink)
  }

  // determine whether the user has logged in
  const hasToken =
    typeof store.state.user != "undefined" &&
    store.state.user != null &&
    typeof store.state.user.kyc != "undefined" &&
    store.state.user.email != null
  if (hasToken) {
    if (to.path === "/sign-in") {
      // if is logged in, redirect to the home page
      next({path: "/calendar"})
      NProgress.done()
    } else {
      console.log("check role")
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        console.log("has role")
        next()
      } else {
        try {
          console.log("try replace router")
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          // const { roles } = await store.dispatch('getInfo')
          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          // next({ ...to, replace: true })
          next()
        } catch (error) {
          console.log("resetToken")
          // remove token and go to login page to re-login
          await store.dispatch("resetToken")
          Message.error(error || "Has Error")
          console.log("authLink", authLink)
          window.location = authLink
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    console.log("no token check whitelist", whiteList.indexOf(to.path))
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      console.log("no token authLink", authLink)
      // other pages that do not have permission to access are redirected to the login page.
      // window.location =  authLink;
      console.log("to", to)
      window.location =
        process.env.VUE_APP_BASE_URL +
        "/sign-in?redirect=" +
        process.env.VUE_APP_BASE_URL +
        to.path
      NProgress.done()
    }
  }
})

function saveUser(User, session, getCookieId, authLink) {
  return new Promise((resolve, reject) => {
    HTTP.post(`save/user`, User)
      .then((res) => {
        if (res.data.success) {
          store.commit("SET_USER", res.data.obj)
          let obj = {
            label: "profileImage",
            value: User.profileImage,
          }
          store.commit("SET_USER_OBJ", obj)
          let obj2 = {
            label: "profileImageId",
            value: User.profileImageId,
          }
          store.commit("SET_USER_OBJ", obj2)
          console.log("Saved! user", User, res.data.obj)
        }
        resolve()
      })
      .catch(async (e) => {
        //setTimeout(async () => {
        User = await getProfile(User, session, getCookieId, authLink)
        await saveUser(User, session, getCookieId, authLink)
        //}, 500);
        console.log(e.response)
        reject(e)
      })
  })
}

function getCookie(getCookieId, authLink) {
  return new Promise((resolve, reject) => {
    HTTPDtm.post(`getCookie`, getCookieId, {withCredentials: true})
      .then((res) => {
        console.log("found token", res)
        // alert("found token" + JSON.stringify(res.data))
        let token = {
          accessToken:
            res.data.dtmSession.accessToken || res.data.dtmSession.token,
          refreshToken: res.data.dtmSession.refreshToken,
        }

        // if (res.data.dtmSession.accessToken) {
        //   token.accessToken = res.data.dtmSession.accessToken
        // } else if (res.data.dtmSession.token) {
        //   token.accessToken = res.data.dtmSession.token
        // } else {
        //    alert("token error")
        // }
        // console.log("cookie token", token)
        resolve(token)
      })
      .catch((e) => {
        console.log(e)
        console.log("authLink", authLink)
        window.location = "https://dtm.avalue.co.th"
        // $liff.openWindow({
        //   url: authLink
        // })
        reject(e)
      })
  })
}

function getRefreshToken(User, getCookieId, authLink) {
  console.log("getRefreshToken", User)
  return new Promise((resolve, reject) => {
    getCookieId.refreshToken = User.refreshToken
    HTTPDtm.post(`refreshTokenProvider`, getCookieId, {withCredentials: true})
      .then((res) => {
        console.log("refreshTokenProvider res.data", res.data)
        if (res.data.successful) {
          User = res.data
          User.accessToken = res.data.accessToken || res.data.refreshToken
          User.refreshToken = res.data.refreshToken
          console.log("user", User)

          setTimeout(async () => {
            User = await getProfile(User, session, getCookieId, authLink)
          }, 500)
        }
        resolve(User)
      })
      .catch((e) => {
        if (e.response.status == 400) {
          console.log(authLink)
          window.location = authLink
        }
        reject(e)
      })
  })
}

function getProfile(User, session, getCookieId, authLink) {
  return new Promise((resolve, reject) => {
    console.log("getProfile", session)
    HTTPDtm.defaults.headers.common.Authorization =
      "Bearer " + session.accessToken
    HTTPDtm.get(`profile`)
      .then((res) => {
        if (res.data.successful) {
          User = res.data
          User.accessToken = session.accessToken
          User.refreshToken = session.refreshToken
          console.log("user", User)
        }
        resolve(User)
      })
      .catch(async (e) => {
        if (e.response.status == 401) {
          console.log("getProfile Unauthorized", User, session)
          console.log("Unauthorized getCookieId", getCookieId)
          User.accessToken = session.accessToken
          User.refreshToken = session.refreshToken

          User = await getRefreshToken(User, getCookieId, authLink)
          resolve(User)
        }
        console.log("getProfile error", e.response)
        reject(e)
      })
  })
}

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

export default router
